import { Redirect, Router } from '@reach/router';
import React, { memo } from 'react';
import Dashboard from './app/dashboard';
import Create from './app/create';
import Builder from './app/builder';
import CLBuilder from './app/clbuilder';
import Wizard from './app/wizard';
import NotFound from './404';
import PrivateRoute from '../components/router/PrivateRoute';
import Wrapper from '../components/shared/Wrapper';

const App = () => (
  <Wrapper>
    <Router>
      <Redirect noThrow from="/app" to="/app/dashboard" exact />
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <PrivateRoute path="/app/builder/:id" component={Builder} />
      <PrivateRoute path="/app/clbuilder/:id" component={CLBuilder} />
      <PrivateRoute path="/app/create/:type/:template" component={Create} />
      <PrivateRoute path="/app/wizard/:id" component={Wizard} />
      <NotFound default />
    </Router>
  </Wrapper>
);
export default memo(App);
